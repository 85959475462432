
function Privacy() {
    return (
        <div style={{width:'100vw', height:'100vh'}}>
            <iframe
                src={`${process.env.PUBLIC_URL}/privacy-aletti.pdf`} width="100%" height="100%">
            </iframe>
        </div>

    )
}

export default Privacy;