import {useNavigate, useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import {API_URL, TOOLPRESS_API} from "../config";
import {Alert, Button, CircularProgress, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {useState} from "react";

import registerStyle from "./Register.module.css";

import logo from "../assets/images/logo-new.png";
import axios from "axios";

function Register() {
    const {id} = useParams();
    const [{data, loading, error}, refetch] = useAxios(`${API_URL}user/${id}`);

    const [partecipazione, setPartecipazione] = useState('');

    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);

    const navigate = useNavigate();

    function handleRegistration() {
        setIsLoading(true);

        axios.post(`${API_URL}register/${id}`, {partecipazione:partecipazione}, {
            headers: {
                // Set any headers as needed
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                // Handle success
                setSuccess(true);
                setApiError(null);
            })
            .catch(error => {
                setSuccess(false);
                setApiError(error.response.data.error);
            })
            .finally(() => {
                setIsLoading(false); // Nasconde l'indicatore di caricamento
            });
    }

    function toStreaming() {
        navigate(`/streaming/${id}`);
    }

    if(loading) {
        <CircularProgress></CircularProgress>
    }

    if(!data && !loading) {
        return (
            <div style={{ width:"100vw", height:"100vh", alignContent:"center", justifyItems:"center", backgroundColor: "#0f2e5d", textAlign:"center", fontFamily: "futura-pt, sans-serif"}}>
                <h1 style={{fontWeight:"normal", color:"white"}}>
                    Utente non abilitato all'accesso
                </h1>
            </div>
        )
    }

    if(!success && data && data.partecipazione) {
        setSuccess(true);
        setPartecipazione(data.partecipazione);
    }

    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            justifyContent:"center",
            alignItems:"center",
            display:"flex",
            backgroundColor: "#0f2e5d",
            fontFamily: "futura-pt, sans-serif"
        }}>
            <div className={registerStyle.container}>
                <div style={{
                    backgroundColor: "white",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    display: "flex",
                }}>
                    <img src={logo} alt={''}/>
                    <hr style={{width: "100%"}}/>
                </div>

                { success &&
                    <div style={{margin:0, textAlign:"center"}}>
                        { partecipazione==="si" &&
                            <>
                                <h3 style={{marginTop: 0}}>
                                    La sua registrazione all’evento digitale del 23 aprile delle ore 17.30 di Banca
                                    Aletti è avvenuta con successo! <br/>
                                </h3>
                                <h3 style={{marginTop: 0,fontWeight:'500', marginBottom:"1%"}}>
                                    Ecco il link per accedere all'evento:
                                    <br/>
                                </h3>
                                <Button
                                    variant="contained"
                                    style={{backgroundColor: "#0f2e5d", borderRadius: "50px", marginBottom:"3%"}}
                                    onClick={toStreaming}
                                >
                                    Guarda la diretta
                                </Button>
                                <h3 style={{marginTop: 0,fontWeight:'500'}}>
                                    Per qualsiasi informazione può contattare la segreteria organizzativa .
                                    <br/>
                                    E-mail: <a href={"mailto:segreteria@bancaalettidigital.com"}>segreteria@bancaalettidigital.com </a>
                                </h3>
                            </>

                        }
                        {partecipazione === "no" &&
                            <>
                                <h3 style={{marginTop: 0}}>
                                    Ci dispiace che non possa partecipare. <br/>
                                </h3>
                                <h3 style={{marginTop: 0, fontWeight: '500',marginBottom:"1%"}}>
                                    Se ha declinato l’invito per errore potrà comunque accedere all'evento qui:
                                </h3>
                                <Button
                                    variant="contained"
                                    style={{backgroundColor: "#0f2e5d", borderRadius: "50px", marginBottom:"3%"}}
                                    onClick={toStreaming}
                                >
                                    Guarda la diretta
                                </Button>
                                <h3 style={{marginTop: 0, fontWeight: '500'}}>
                                    Per qualsiasi informazione può contattare la segreteria organizzativa .
                                    <br/>
                                    E-mail: <a
                                    href={"mailto:segreteria@bancaalettidigital.com"}>segreteria@bancaalettidigital.com </a>
                                </h3>
                            </>

                        }
                    </div>
                }
                {!success &&
                    <>
                        <h2 style={{fontWeight: "normal", marginTop: "0"}}>Parteciperà all'evento online dal
                            titolo <span
                                style={{fontWeight: "bold"}}>"Insieme, un cambio di Prospettiva"?</span></h2>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={partecipazione}
                            onChange={(event) => {
                                setPartecipazione(event.target.value)
                            }}
                        >
                            <FormControlLabel value="si" control={<Radio/>} label="PARTECIPO"/>
                            <FormControlLabel value="no" control={<Radio/>} label="NON PARTECIPO"/>
                        </RadioGroup>
                        <div style={{
                            backgroundColor: "white",
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            display: "flex",
                        }}>
                            {isLoading &&
                                <CircularProgress></CircularProgress>
                            }
                            {!isLoading &&
                                <Button
                                    variant="contained"
                                    style={{backgroundColor: "#0f2e5d", borderRadius: "50px"}}
                                    onClick={handleRegistration}
                                >
                                    invia la risposta
                                </Button>
                            }
                        </div>
                    </>
                }

            </div>
            {apiError &&
                <Alert className={registerStyle.alert} severity="error">
                    {apiError}
                    <br/>
                    Contattare la segreteria organizzativa: segreteria@bancaalettidigital.com
                </Alert>
            }

        </div>
    )

}

export default Register;