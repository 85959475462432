

function Unavailable() {
    return (
        <div style={{ width:"100vw", height:"100vh", alignContent:"center", justifyItems:"center", backgroundColor: "#0f2e5d", textAlign:"center", fontFamily: "futura-pt, sans-serif"}}>
            <h1 style={{fontWeight:"normal", color:"white"}}>
                Utente non abilitato all'accesso
            </h1>
        </div>
    )
}

export default Unavailable;