import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Streaming from "./pages/Streaming";
import Privacy from "./pages/Privacy";
import Unavailable from "./pages/Unavailable";
import Register from "./pages/Register";

const router = createBrowserRouter([
  { path: '/streaming/:id', element: <Streaming/>},
  { path: '/streaming', element: <Unavailable/>},
  { path: '/privacy', element: <Privacy/>},
  { path: '/register/:id', element: <Register/>},
  { path: '/register', element: <Unavailable/>},
  { path: '/', element: <Unavailable/>}
])

function App() {
  return <RouterProvider router={router} />;
}

export default App;
