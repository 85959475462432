import streamingStyle from "./Streaming.module.css";

import logo from "../assets/images/logo.svg"
import qeaIcon from "../assets/images/qea-icon.svg";
import {Alert, Button, CircularProgress, TextField} from "@mui/material";
import {useEffect, useState} from "react";

import {API_URL, TOOLPRESS_API} from "../config";
import axios from "axios";
import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
function Streaming() {

    const [question, setQuestion] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alternativePlayer, setAlternativePlayer] = useState(false);

    const {id} = useParams();
    const [{data, loading, error}, refetch] = useAxios(`${API_URL}user/${id}`)

    useEffect(() => {
        if(data) {
            setName(data.NAME);
            setSurname(data.SURNAME);
            setEmail(data.EMAIL);
        }

    }, [data])

    const handleChangeValue = (e) => {
        setQuestion(e.target.value);
    }

    const postQuestions = async () => {
        setSending(true);
        await axios.post(`${TOOLPRESS_API}/question/AWmVHGxBywTGl0YWwzvY`, {question: {domanda: question, Email: email, Nome: name, Cognome:surname }});
        setSending(false);
        setQuestion('');
        setSuccess(true);

        setTimeout(() => {
            setSuccess(false);
        }, 3000);
    }

    const switchPlayer = () => {
        setAlternativePlayer(!alternativePlayer);
    }

    if(loading) {
        <CircularProgress></CircularProgress>
    }

    if((!data && !loading)) {
        return (
            <div style={{ width:"100vw", height:"100vh", alignContent:"center", justifyItems:"center", backgroundColor: "#0f2e5d", textAlign:"center", fontFamily: "futura-pt, sans-serif"}}>
                <h1 style={{fontWeight:"normal", color:"white"}}>
                    Utente non abilitato all'accesso
                </h1>
            </div>
        )
    }

    return(
        <div className={streamingStyle.mainContainer}>
            {success &&
                <div className={streamingStyle.alertContainer}>
                    <Alert severity="success">Domanda inviata con successo.</Alert>
                </div>
            }
            <img src={logo} alt={''} className={streamingStyle.logo}/>
            <hr className={streamingStyle.hrLine}/>
            {/*<img src={lineImg} alt={''} className={streamingStyle.topLine}/>*/}
            <div className={streamingStyle.videoContainer}>
                {!alternativePlayer &&
                    <iframe
                        src="https://player.vimeo.com/video/938527421?h=7ea4708ec4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{width: "100%", height: "100%"}} title="BANCA ALETTI">
                    </iframe>

                }
                {alternativePlayer &&
                    <iframe
                        src="https://player.vimeo.com/video/938527421?h=7ea4708ec4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{width: "100%", height: "100%"}} title="BANCA ALETTI">
                    </iframe>
                }

            </div>
            <div className={streamingStyle.alternativeContainer}>
                <p style={{marginBottom: "0", textAlign: "right", marginLeft: "auto"}}>
                    Problemi di visualizzazione?
                    Usa il <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={switchPlayer}> Player Alternativo </span>
                </p>
            </div>
            <hr className={streamingStyle.hrLine}/>
            <div className={streamingStyle.inputContainer}>
                <div className={streamingStyle.inputHeader}>
                    <img src={qeaIcon} alt={''} className={streamingStyle.qeaIcon}/>
                    <h3 style={{marginLeft: '1%', marginTop:0, marginBottom:"1%"}}> Fai una domanda.</h3>
                </div>
                <div className={streamingStyle.qeaContainer}>
                    {sending &&
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <CircularProgress>

                            </CircularProgress>
                        </div>

                    }
                    <TextField
                        onChange={(e) => handleChangeValue(e)}
                        value={question}
                        multiline
                        rows={2}
                        variant="outlined"
                        fullWidth
                        sx={{

                            '& .MuiOutlinedInput-root': {
                                // Target the input field container for styling
                                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Transparent gray background
                                borderColor: 'white', // For border color
                                color: 'white',
                                fontFamily: "futura-pt, sans-serif",
                                '& fieldset': {
                                    borderColor: 'white', // Change border color
                                },
                                '&:hover fieldset': {
                                    borderColor: 'white', // Change border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white', // Change border color when focused
                                },
                            },
                        }}
                    >
                    </TextField>


                    <Button
                        onClick={postQuestions}
                        onChange={(e) => handleChangeValue(e)}
                        variant="outlined"
                        disabled={question === ''}
                        sx={{
                            color: 'white', // Colore del testo
                            borderColor: 'white', // Colore del bordo
                            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Sfondo grigio trasparente
                            fontFamily: "futura-pt, sans-serif",
                            fontSize: "1.5vw",
                            marginLeft: "1.5vw",
                            width: "10vw",
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.2)', // Sfondo un po' più scuro al passaggio del mouse
                                borderColor: 'white', // Mantiene il bordo bianco anche al hover
                            },
                            '&.Mui-disabled': {
                                borderColor: 'rgba(255, 255, 255, 0.3)', // Bordo più chiaro quando il bottone è disabilitato
                                color: 'rgba(255, 255, 255, 0.3)', // Testo più chiaro quando il bottone è disabilitato
                            },
                            '@media (max-width:600px)': {
                                fontSize: '5vw', // Cambia la dimensione del font per viewport sotto i 600px
                                width: '40vw', // Cambia la larghezza del bottone per viewport sotto i 600px
                            },
                        }}
                    >
                        Invia
                    </Button>

                </div>
                <p style={{marginTop:"1%"}}>Nei prossimi giorni ricollegati a questo link per rivedere l’evento e ricevere la tua risposta</p>
            </div>


        </div>
    )

}

export default Streaming;